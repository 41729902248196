import { DATE_TIME_FORMAT, UK_TIMEZONE } from "../constants/common";
import moment from "moment-timezone";
import * as H from "history";
import axios from "axios";
import { API } from "helpers/constants/api";

export const downloadFileRest = (response: any, fileName: string) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const bytesToSize = (bytes: number) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "n/a";
  const i = Number(Math.floor(Math.log(bytes) / Math.log(1024)));
  if (i === 0) return `${bytes} ${sizes[i]}`;
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

export function isEmpty(obj: object) {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
}

export const renderNoInList = (
  page: number,
  index: number,
  pageSize: number
) => {
  const no = (page - 1) * pageSize + index + 1;
  return no < 10 ? "0" + no : no;
};

export const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const convertDateToTimestamp = (
  dateString: string,
  isEndDate = false
) => {
  const chunks = dateString.split("/");
  const dateObject = moment.tz(
    `${chunks[2]}-${chunks[1]}-${chunks[0]} 00:00`,
    UK_TIMEZONE
  );
  let dateTimestamp = moment(dateObject).format("x");

  if (isEndDate) {
    dateTimestamp = moment(dateObject).add(1, "days").format("x");
    const endDateTimestamp = Number(dateTimestamp) - 1000;
    return endDateTimestamp;
  }

  return Number(dateTimestamp);
};

export const modifyQueryParams = (
  history: H.History<unknown>,
  path: string,
  query: any | URLSearchParams
) => {
  let queryStrings = "";
  if (query instanceof URLSearchParams) {
    queryStrings = query.toString();
  } else {
    queryStrings = new URLSearchParams(query).toString();
  }
  history.push({
    pathname: path,
    search: `?${queryStrings}`,
  });
};

export const convertTimestampToDate = (timestamp: number) => {
  return moment(timestamp).tz(UK_TIMEZONE).format(DATE_TIME_FORMAT);
};

// DD/MM/YYYY -> DDMMYY
export const convertTimeForReportDownload = (date: string) => {
  const divide = date.split("/");
  const year = divide[2].substring(2);
  const newDate = divide[0] + divide[1] + year;
  return newDate;
};

export const transformDropdownListItemResponse = (
  list: { id: number; name: string }[]
) => {
  const newList = list.map((el) => {
    return {
      label: el.name,
      value: el.id,
    };
  });

  return newList;
};

export const titleCase = (str: string) => {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  var splitStr2 = splitStr.join(" ").split("-");
  for (var e = 0; e < splitStr2.length; e++) {
    splitStr2[e] =
      splitStr2[e].charAt(0).toUpperCase() + splitStr2[e].substring(1);
  }
  return splitStr2.join("-");
};

export const truncateNumberToTwoDecimalWithoutRounding = (
  original: number | string
): number => {
  const with2Decimals = original.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
  return +with2Decimals;
};

export const includeCommasIntoNumber = (number: string) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const roundDecimalNumberForChart = (org: number) => {
  const num = Math.round(org * 10000) / 100;
  if (num === 0) return null;
  return num;
};

export const convertValueToHoldingNumber = (value: string) => {
  let text = value.replace(/\D/g, "");
  if (text.length > 2) {
    text = text.replace(/.{2}/, "$&/");
  }
  if (text.length > 6) {
    text = text.replace(/.{6}/, "$&/");
  }

  return text;
};

export const parseAlternate = (alternateValue: string) => {
  let temps = [];
  try {
    const alternates = JSON.parse(alternateValue);
    temps = alternates.map((el: string) => {
      return {
        name: el,
      };
    });
  } catch {
    temps = [{ name: alternateValue }];
  }
  return temps;
};

export function handleKeyPress(
  event: React.KeyboardEvent<HTMLInputElement>
): void {
  const keyCode: number = event.keyCode || event.which;
  const keyValue: string = String.fromCharCode(keyCode);
  if (!/^\d+$/.test(keyValue)) {
    event.preventDefault();
  }
}

export const convertDataOptions = (
  arr: Array<any>,
  nameId: string,
  nameLabel: string
) => {
  return arr && nameId && nameLabel
    ? arr.map((r: any) => {
        return {
          ...r,
          id: r[nameId].toString(),
          label: r[nameLabel],
        };
      })
    : [];
};

export const convertFilter = (arr: Array<any>, arrFilter: string) => {
  return arr && arrFilter
    ? arr.filter((item) =>
        JSON.parse(arrFilter).find((element: string) => element === item.id)
      )
    : [];
};