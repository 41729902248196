import { gql } from "@apollo/client";
import { DROPDOWN_LIST_PGA_FORM } from "./fragments";

export const GET_NOTIFICATION_HUB_TOKEN = gql`
  query {
    getTokenForNotificationHub {
      hubToken
    }
  }
`;

export const GET_ALERTS_FOR_CMS = gql`
  query getAlertsForCms($skip: Int, $pageSize: Int) {
    getAlertsForCms(skip: $skip, take: $pageSize) {
      items {
        alertId
        title
        alertStatus
        createdDateTime
        sendDateTime
        totalAlert
        totalOpened
      }
      totalCount
    }
  }
`;

export const GET_ALERTS_DETAIL_FOR_CMS = gql`
  query getAlertDetailForCms($input: GetAlertDetailForCmsRequestInput) {
    getAlertDetailForCms(getAlertDetailForCmsRequestInput: $input) {
      responseCode
      errorCode
      errorMessage
      title
      sendDateTime
      content
    }
  }
`;

export const DOWNLOAD_RECIPIENT_LIST = gql`
  query downloadRecipientList($input: DownloadRecipientListRequestInput) {
    downloadRecipientList(downloadRecipientListRequestInput: $input) {
      responseCode
      errorCode
      errorMessage
      fileName
    }
  }
`;

export const GET_DOCUMENT_HUB_FILE = gql`
  query getDocumentHubFile(
    $getDocumentHubFileInput: GetDocumentHubFileRequestInput
  ) {
    getDocumentHubFile(getDocumentHubFileInput: $getDocumentHubFileInput) {
      totalPages
      totalCount
      files {
        documentId
        title
        subTitle
        tags
        fileName
        createdDate
        createdByUserName
        createdByUserEmail
      }
    }
  }
`;

export const GET_PRODUCERS_FOR_CMS = gql`
  query getProducersForCms($input: GetProducerForCmsRequestInput) {
    getProducersForCms(input: $input) {
      producerDto {
        producerId
        email
        active
        holdingNumber
        country
        lastLoginDatetime
        accountStatus
      }
      totalCount
    }
  }
`;

export const GET_CMS_REPORT_FOR_ADMIN = gql`
  query getCmsReportForAdmin($skip: Int, $pageSize: Int) {
    getCmsReportForAdmin(skip: $skip, take: $pageSize) {
      items {
        reportId
        name
        typeId
        typeName
        startDate
        endDate
        country
        site
        species
        createdDate
        noteProgress
        preference
        pGAUpdatedBy
        pGAStatus
        producers
      }
      totalCount
    }
  }
`;

export const GET_USERS_FOR_CMS = gql`
  query getUserList($input: GetUserListRequestInput) {
    getUserList(getUserListRequestInput: $input) {
      responseCode
      totalPage
      totalCount
      users {
        userId
        name
        email
        jobTittle
        active
        abbs {
          abb
          abbName
        }
        roleIds {
          roleId
          name
        }
      }
    }
  }
`;

export const GET_ROLE_FOR_CMS = gql`
  query getRoles {
    getRoles {
      responseCode
      roles {
        roleId
        name
      }
    }
  }
`;

export const GET_CMS_REPORT_EXECUTION = gql`
  query getCmsReportExecution(
    $getCmsReportExecutionRequestInput: GetCmsReportExecutionRequestInput
  ) {
    getCmsReportExecution(
      getCmsReportExecutionRequestInput: $getCmsReportExecutionRequestInput
    ) {
      errorCode
      errorMessage
      responseCode
      fileName
      reportTitle
      totalCount
      totalPage
      activityReport {
        producerId
        tradingName
        farmName
        loggedInDate
        county
        buyer
        userType
        sites
      }
      registrationReport {
        producerId
        tradingName
        farmName
        registeredDate
        county
      }
      killDataReport
      marketingReport {
        producerId
        tradingName
        subscribeEmail
        subscribeCall
        subscribePost
        subscribeText
      }
    }
  }
`;

export const GET_PRODUCERS_FOR_BUYER = gql`
  query getProducersForBuyer($input: GetProducerForBuyerRequestInput) {
    getProducersForBuyer(input: $input) {
      producerDto {
        producerId
        fullName
        tradingName
        email
        address1
        holdingNumber
        isFavorite
        lastLoginDatetime
        active
        accountStatus
      }
      totalCount
    }
  }
`;

export const GET_AUTO_TOKEN = gql`
  query generateAutoTokenQuery($input: GenerateAutoTokenRequestInput) {
    generateAutoToken(input: $input) {
      errorCode
      errorMessage
      responseCode
      autoLoginUrl
    }
  }
`;

export const GET_LIST_PRODUCER_INFO_TO_VALIDATION = gql`
  query getListProducerInfoToValidation(
    $getListProducerInfoToValidationInput: GetListProducerInfoToValidationInput!
  ) {
    getListProducerInfoToValidation(
      getListProducerInfoToValidationInput: $getListProducerInfoToValidationInput
    ) {
      responseCode
      errorCode
      errorMessage
      isValidAll
      producerInfoToValidation {
        producerId
        producerName
        isValid
        producerErrorType
      }
    }
  }
`;

export const GET_CATEGORIES_FOR_DOCUMENT_HUB = gql`
  query getCategoriesForDocumentHub {
    getCategoriesForDocumentHub {
      categoryId
      categoryName
      orderIndex
    }
  }
`;

export const GET_SITE_AVAILABLE = gql`
  query GetSitesAvailable {
    getSitesAvailable {
      siteId
      siteAbb
    }
  }
`;

export const GET_CMS_REPORT_TYPES = gql`
  query getCmsReportTypes {
    getCmsReportTypes {
      typeId
      typeName
      description
      orderIndex
    }
  }
`;

export const CHECK_STATUS_CMS_REPORT_GENERATING = gql`
  query checkStatusCmsReportGenerating {
    checkStatusCmsReportGenerating {
      responseCode
      errorCode
      errorMessage
      isGenerating
      reportType
    }
  }
`;
export const GET_MAINTENANCE_STATUS = gql`
  query getMaintenanceStatus {
    getMaintenanceStatus {
      errorCode
      errorMessage
      responseCode
      isUnderMaintenance
    }
  }
`;

export const GET_AGREEMENT_FORMS_LIST = gql`
  query getAgreementFormsList(
    $getAgreementFormsListRequestInput: GetAgreementFormsListRequestInput
  ) {
    getAgreementFormsList(
      getAgreementFormsListInput: $getAgreementFormsListRequestInput
    ) {
      errorCode
      errorMessage
      responseCode
      forms {
        producerId
        agreementFormId
        tradingName
        holdingNumber
        lastUpdated
        expiredIn
        sites
        isReviewed
        lastUpdatedRole
        formYear
      }
      totalCountCurrentTab
      totalNotStarted
      totalDraft
      totalSubmitted
      totalApproved
    }
  }
`;

export const GET_DROPDOWN_VALUES_PGA_FORM = gql`
  ${DROPDOWN_LIST_PGA_FORM}
  query getDropdownValuesPGAForm {
    OrganicSchemes: getOrganicSchemes {
      ...dropdownListPGAForm
    }
    FarmAssuraceSchemes: getFarmAssuraceSchemes {
      ...dropdownListPGAForm
    }
    EnvironmentalSchemes: getEnvironmentalSchemes {
      ...dropdownListPGAForm
    }
    BeddingMaterials: getBeddingMaterials {
      ...dropdownListPGAForm
    }
    ForecastedTypesCattle: getForecastedTypesForCattle {
      ...dropdownListPGAForm
    }
    ForecastedTypesSheep: getForecastedTypesForSheep {
      ...dropdownListPGAForm
    }
    SoyaOrigins: getSoyaOrigins {
      ...dropdownListPGAForm
    }
    PalmOrigins: getPalmOrigins {
      ...dropdownListPGAForm
    }
    SheepBreeds: getSheepBreeds {
      ...dropdownListPGAForm
    }
    FarmEnterprises: getFarmEnterprises {
      ...dropdownListPGAForm
    }
    FarmVetPractices: getFarmVetPractices {
      ...dropdownListPGAForm
    }
    SemenSuppliers: getSemenSuppliers {
      ...dropdownListPGAForm
    }
    BioDiversities: getBioDiversities {
      ...dropdownListPGAForm
    }
    CarbonCalculators: getCarbonCalculators {
      ...dropdownListPGAForm
    }
    GetTenYearsUntilPGAFormCreatedDate: getTenYearsUntilPGAFormCreatedDate {
      ...dropdownListPGAForm
    }
    CarbonReductionPlans: getCarbonReductionPlans {
      ...dropdownListPGAForm
    }
    CarbonFootprintUnits: getCarbonFootprintUnits {
      ...dropdownListPGAForm
    }
    GetInterestedInOptions: getInterestedInOptions {
      ...dropdownListPGAForm
    }
  }
`;

export const GET_DROPDOWN_VALUES_PGA_FORM_2025 = gql`
  ${DROPDOWN_LIST_PGA_FORM}
  query getDropdownValuesPGAForm {
    OrganicSchemes: getOrganicSchemes {
      ...dropdownListPGAForm
    }
    FarmAssuraceSchemes: getFarmAssuraceSchemesNew {
      ...dropdownListPGAForm
    }
    EnvironmentalSchemes: getEnvironmentalSchemes {
      ...dropdownListPGAForm
    }
    BeddingMaterials: getBeddingMaterials {
      ...dropdownListPGAForm
    }
    ForecastedTypesCattle: getForecastedTypesForCattle {
      ...dropdownListPGAForm
    }
    ForecastedTypesSheep: getForecastedTypesForSheep {
      ...dropdownListPGAForm
    }
    SoyaOrigins: getSoyaOrigins {
      ...dropdownListPGAForm
    }
    PalmOrigins: getPalmOrigins {
      ...dropdownListPGAForm
    }
    SheepBreeds: getSheepBreeds {
      ...dropdownListPGAForm
    }
    FarmEnterprises: getFarmEnterprises {
      ...dropdownListPGAForm
    }
    FarmVetPractices: getFarmVetPractices {
      ...dropdownListPGAForm
    }
    SemenSuppliers: getSemenSuppliers {
      ...dropdownListPGAForm
    }
    BioDiversities: getBioDiversities {
      ...dropdownListPGAForm
    }
    CarbonCalculators: getCarbonCalculators {
      ...dropdownListPGAForm
    }
    GetTenYearsUntilPGAFormCreatedDate: getTenYearsUntilPGAFormCreatedDate {
      ...dropdownListPGAForm
    }
    CarbonReductionPlans: getCarbonReductionPlans {
      ...dropdownListPGAForm
    }
    CarbonFootprintUnitsNew: getCarbonFootprintUnitsNew {
      ...dropdownListPGAForm
    }
    GetInterestedInOptions: getInterestedInOptions {
      ...dropdownListPGAForm
    }
  }
`;

export const GET_DROPDOWN_VALUES_PGA_FORM_REVIEW = gql`
  ${DROPDOWN_LIST_PGA_FORM}
  query getDropdownValuesPGAForm {
    OrganicSchemes: getOrganicSchemes {
      ...dropdownListPGAForm
    }
    FarmAssuraceSchemes: getFarmAssuraceSchemes {
      ...dropdownListPGAForm
    }
    FarmAssuraceSchemesNew: getFarmAssuraceSchemesNew {
      ...dropdownListPGAForm
    }
    EnvironmentalSchemes: getEnvironmentalSchemes {
      ...dropdownListPGAForm
    }
  }
`;

export const GET_COUNTIES = gql`
  query GetCounties {
    getCounties {
      responseCode
      errorCode
      errorMessage
      counties {
        countyId
        countyName
      }
    }
  }
`;

export const GET_AGREEMENT_FORM_FOR_REVIEW = gql`
  query getAgreementFormForReview(
    $getAgreementFormForReviewRequestInput: GetAgreementFormForReviewRequestInput
  ) {
    getAgreementFormForReview(
      getAgreementFormForReviewInput: $getAgreementFormForReviewRequestInput
    ) {
      errorCode
      errorMessage
      responseCode
      agreementStep1 {
        contactName
        tradingName
        paymentAddressLine1
        addressLine2
        addressLine3
        addressLine4
        postCode
        county
        countryName
        primaryEmailAddress
        alternateEmailAddress
        mainContactNumber
        additionalContactNumber
      }
      agreementStep2 {
        fasMembershipNo
        farmAssuraceSchemeId
        farmAssuraceOtherValues
        primaryHoldingNumber
        additionalHoldingNumber
        organicProducer
        organicSchemeId
        organicSchemeNumber
        organicSchemeOtherValue
        environmentalScheme
        environmentalSchemeId
        environmentalSchemeOtherValue
      }
      differentFields
      approvedFields
      isApprovedAll
    }
  }
`;

export const DOWNLOAD_AGREEMENT_FORMS_LIST = gql`
  query getAgreementFormsList(
    $getAgreementFormsListRequestInput: GetAgreementFormsListRequestInput
  ) {
    getAgreementFormsList(
      getAgreementFormsListInput: $getAgreementFormsListRequestInput
    ) {
      errorCode
      errorMessage
      responseCode
      fileName
    }
  }
`;

export const GET_PGA_FIELD_REVIEW_STATUS = gql`
  query GetPGAFieldReviewStatus(
    $getPGAFieldReviewStatusRequestInput: GetPGAFieldReviewStatusRequestInput
  ) {
    getPGAFieldReviewStatus(
      getPGAFieldReviewStatusInput: $getPGAFieldReviewStatusRequestInput
    ) {
      errorCode
      errorMessage
      responseCode
      fieldStatus {
        currentValue
        prevValue
        approveStatus
      }
    }
  }
`;

export const GET_CURRENT_AGREEMENT_FORM_CMS = gql`
  query getCurrentAgreementFormCms(
    $getCurrentAgreementFormInput: GetCurrentAgreementFormRequestInput
  ) {
    getCurrentAgreementFormCms(
      getCurrentAgreementFormInput: $getCurrentAgreementFormInput
    ) {
      responseCode
      errorCode
      errorMessage

      agreementFormId
      producerId
      producerName
      currentStep
      progressStatusId
      progressStatusName
      displayStep

      lastUpdatedRole
      agreementStep1Dto {
        contactName
        tradingName
        paymentAddressLine1
        addressLine2
        addressLine3
        addressLine4
        postCode
        county
        countryName
        primaryEmailAddress
        alternateEmailAddress
        mainContactNumber
        additionalContactNumber
      }
      agreementStep2Dto {
        fasMembershipNo
        farmAssuraceSchemeId
        farmAssuraceOtherValues
        primaryHoldingNumber
        additionalHoldingNumber
        organicProducer
        organicSchemeNumber
        organicSchemeId
        organicSchemeOtherValue
        environmentalScheme
        environmentalSchemeId
        environmentalSchemeOtherValue
        uploadAntibioticData
        medicineUsage
        howMedicineUsageRecord
      }
      agreementStep3Dto {
        finishCattleForSlaughter
        supplyCattle
        herdPrefix
        homeBredCattle
        cattleToFinish
        cattleOutdoorsInWinter
        beddingMaterialId
        beddingMaterialOtherValue
        numbersPurchasedObject
        forecastedSupplyObject
        approveFinishingUnit
        unitGrazing
        grassDietPercentage
        feedInfoObject
        feedContainSoya
        soyaFeedingObject
        feedContainPalmOil
        palmFeedingObject
        soyaFeedingForCattle
        soyaFeedingForCattleObjects {
          certifiedDeforestationFree
          details
        }
        palmFeedingForCattle
        palmFeedingForCattleObjects {
          certifiedDeforestationFree
          details
        }
      }
      agreementStep4Dto {
        finishLambForSlaughter
        supplySheep
        flockNumber
        additionalFlockNumber
        flockSize
        eweBreedIds
        eweOther
        ramBreedIds
        ramOther
        forecastedSupplyObject
        geneticLambs
        feedInfoObject
        feedContainSoya
        soyaFeedingObject
        feedContainPalmOil
        palmFeedingObject
        soyaFeedingForSheep
        palmFeedingForSheep
        soyaFeedingForSheepObjects {
          certifiedDeforestationFree
          details
        }
        palmFeedingForSheepObjects {
          certifiedDeforestationFree
          details
        }
      }
      agreementStep5Dto {
        additionalFarmEnterprise
        farmEnterpriseIds
        farmEnterpriseOther
        farmVetPractice
        farmVetOtherAdd
        farmVetOtherName
        farmVetOtherPostCode
        farmVetOtherPhoneNo
        useAI
        companySemenSupplyIds
        companySemenSupplyOther
        companyAIServiceIds
        companyAIServiceOther
        financeForWeanedCalves
        interestedInOptions
        interestedInResearchOrProducerGroup
        farmSoftware
        farmSoftwareUse
      }
      agreementStep6Dto {
        biodiversityIds
        bioDiversityOtherValue
        soilManagementPlan
        waterManagementPlan
        renewableEnergy
        measuredCarbonFootprint
        carbonCaculatorId
        carbonCaculatorOtherValue
        carbonFootprintCalculatedYear
        carbonFootprintResultObject
        carbonReductionPlan
        carbonReductionPlanIds
        carbonReductionPlanOtherValue
        interestedInSustainabilityProgramme
        peatExposure
        peatReductionPlan
        sellCarbonCredit
        nutrientManagementPlan
        environmentalInitiatives
        environmentalInitiativeObjects {
          valueId
          details
        }
        governmentEnvironmentalSchemes
        governmentEnvironmentalSchemeObjects {
          countryId
          countryName
          schemes
          orderIndex
        }
      }
    }
  }
`;

export const GET_COUNTRY_BY_COUNTY_ID = gql`
  query GetCountryByCountyId($countyId: String) {
    getCountryByCountyId(countyId: $countyId) {
      responseCode
      errorCode
      errorMessage
      country {
        countryId
        countryName
      }
    }
  }
`;

export const GET_FORECASTED_DATES_CMS = gql`
  query getForecastedDates($producerId: String) {
    getForecastedDates(producerId: $producerId) {
      id
      name
    }
  }
`;

export const CHECK_EDIT_FORM_PERMISSION = gql`
  query checkEditFormPermission(
    $checkEditFormPermissionInput: CheckEditFormPermissionRequestInput
  ) {
    checkEditFormPermission(
      checkEditFormPermissionInput: $checkEditFormPermissionInput
    ) {
      responseCode
    }
  }
`;

export const GET_LAST_YEAR_SUPPLY_DATA = gql`
  query getLastYearSupplyData(
    $getLastYearSupplyDataInput: GetLastYearSupplyDataRequestInput
  ) {
    getLastYearSupplyData(
      getLastYearSupplyDataInput: $getLastYearSupplyDataInput
    ) {
      responseCode
      errorCode
      errorMessage
      lastYearSupplyObject
    }
  }
`;

export const GET_LOT_INFO_EMAIL = gql`
  query getLotInfoEmail(
    $getLotInfoEmailRequestInput: GetLotInfoEmailRequestInput
  ) {
    getLotInfoEmail(input: $getLotInfoEmailRequestInput) {
      errorCode
      errorMessage
      responseCode
      totalCount
      lotInfos {
        producerId
        producerName
        killDate
        remitted
        lotId
        species
        site
        abb
        alternateEmailAddress
        sentEmail
        noOfRecipient
        headCount
        buyer
      }
    }
  }
`;

export const GET_EMAIL_RECIPIENTS = gql`
  query getEmailRecipients(
    $getRecipientsLotEmailRequestInput: GetRecipientsLotEmailRequestInput
  ) {
    getRecipientsLotEmail(input: $getRecipientsLotEmailRequestInput) {
      errorCode
      errorMessage
      responseCode
      recipients {
        email
        sendingTime
      }
    }
  }
`;

export const GET_ADMIN_INFO = gql`
  query getAdminInfo {
    getAdminInfo {
      errorCode
      errorMessage
      responseCode
      adminInfos {
        email
        userId
      }
    }
  }
`;

export const CHECK_PRODUCER_VALID = gql`
  query CheckProducerValid($input: CheckProducerValidRequestInput) {
    checkProducerValid(input: $input) {
      errorCode
      errorMessage
      responseCode
      validProducerInfos {
        producer
        name
        isNotFound
      }
    }
  }
`;

export const GET_CARCASE_DETAILS_PDF = gql`
  query getCarcaseDetailsPdf(
    $getCarcaseDetailsPdfRequestInput: GetCarcaseDetailsPdfRequestInput
  ) {
    getCarcaseDetailsPdf(input: $getCarcaseDetailsPdfRequestInput) {
      errorCode
      errorMessage
      responseCode
      fileName
    }
  }
`;

export const GET_SPEC_ID_CATEGORIES = gql`
  query GetSpecIdCategories {
    getSpecIdCategories {
      id
      name
      values {
        id
        value
      }
    }
  }
`;

export const GET_PRODUCERS_FOR_FARM_GROUP = gql`
  query GetProducersForFarmGroup(
    $input: GetProducersForFarmGroupRequestInput!
  ) {
    getProducersForFarmGroup(input: $input) {
      responseCode
      errorCode
      errorMessage
      totalProducers
      producers {
        producerId
        tradingName
      }
    }
  }
`;

export const GET_IDEAL_RANGE = gql`
  query GetIdealRange {
    getIdealRange {
      conf {
        id
        value
        orderPos
      }
      fat {
        id
        value
        orderPos
      }
    }
  }
`;

export const GET_GROUP_APPROVERS = gql`
  query GetGroupApprovers {
    getGroupApprovers {
      responseCode
      errorCode
      errorMessage
      groupApprovers {
        userId
        email
      }
    }
  }
`;

export const GET_PRODUCER_GROUPS = gql`
  query GetProducerGroups($input: GetProducerGroupsRequestInput!) {
    getProducerGroups(input: $input) {
      responseCode
      errorCode
      errorMessage
      groups {
        groupId
        groupName
        description
        specIdCategory
        noOfMembers
        createdBy
        createdDateTime
        status
        approverEmail
        approverUserId
        approvedByName
        approvedTime
        publishedTime
        createdById
        submittedBy
        submittedTime
        rejectedBy
        rejectedTime
        rejectedNotes
        editedBy
        editedTime
      }
      totalCount
    }
  }
`;

export const GET_GROUP_CREATORS = gql`
  query GetGroupCreators {
    getGroupCreators {
      responseCode
      errorCode
      errorMessage
      groupCreators {
        userId
        displayName
      }
    }
  }
`;

export const GET_SPEC_ID_AND_NAME_CATEGORIES = gql`
  query GetSpecIdCategories {
    getSpecIdCategories {
      id
      name
    }
  }
`;

export const GET_GROUP_INPUTS_STEP_1_FOR_REVIEW = gql`
  query GetGroupInputStep1ForReview(
    $input: GetGroupInputStep1ForReviewRequestInput!
  ) {
    getGroupInputStep1ForReview(input: $input) {
      responseCode
      errorCode
      errorMessage
      step1 {
        groupName
        description
        sites
        specIdCategory
        specIdValues
      }
    }
  }
`;

export const CHECK_FARM_GROUP_STATUS = gql`
  query CheckFarmGroupStatus($input: CheckGroupStatusRequestInput!) {
    checkFarmGroupStatus(input: $input) {
      errorCode
      status
      responseCode
      errorMessage
    }
  }
`;

export const GET_GROUP_INPUTS_STEP_2_FOR_REVIEW = gql`
  query GetGroupInputStep2ForReview(
    $input: GetGroupInputStep2ForReviewRequestInput!
  ) {
    getGroupInputStep2ForReview(input: $input) {
      responseCode
      errorCode
      errorMessage
      totalRows
      producers {
        producerId
        tradingName
      }
    }
  }
`;

export const GET_GROUP_INPUT_STEP_3_FOR_REVIEW = gql`
  query GetGroupInputStep3ForReview(
    $input: GetGroupInputStep3ForReviewRequestInput!
  ) {
    getGroupInputStep3ForReview(input: $input) {
      responseCode
      errorCode
      errorMessage
      step3 {
        bulls {
          weight
          age
          conf
          fat
        }
        steers {
          weight
          age
          conf
          fat
        }
        heifers {
          weight
          age
          conf
          fat
        }
      }
    }
  }
`;

export const GET_BUYER_SITES = gql`
  query GetBuyerSites($input: GetBuyerSitesRequestInput!) {
    getBuyerSites(input: $input) {
      responseCode
      errorCode
      errorMessage
      siteAbbs {
        siteAbb
        siteId
      }
    }
  }
`;

export const GET_ENVIROMENTAL_INITIATIVES = gql`
  query GetEnvironmentalInitiatives {
    getEnvironmentalInitiatives {
      id
      name
      orderIndex
    }
  }
`;

export const GET_PRODUCER_DOCUMENTS = gql`
  query GetProducerDocumentsForCms($input: GetProducerDocumentsCmsRequestInput) {
    getProducerDocumentsForCms(input: $input) {
      responseCode
      errorCode
      errorMessage
      totalCount
      producerDocuments {
        documentId
        description
        fileName
        lastUpdatedDatetime
        producerId
        tradingName
        updatedBy
      }
    }
  }
`;
