export const API = {
  uploadFile: `${window.REACT_APP_API_GATEWAY_URL}/cms/api/Asset/UploadFile`,
  downloadFile: `${window.REACT_APP_API_GATEWAY_URL}/cms/api/Asset/DownloadFileByDocId?documentId=`,
  downloadFileByName: `${window.REACT_APP_API_GATEWAY_URL}/cms/api/Asset/DownloadFileByFileName?fileName=`,
  downloadFileByReportId: `${window.REACT_APP_API_GATEWAY_URL}/cms/api/Asset/DownloadCmsFileById?reportId=`,
  downloadFileProducerIdsByReportId: `${window.REACT_APP_API_GATEWAY_URL}/cms/api/Asset/DownloadProducerListReport?reportId=`,
  downloadFileByFileName: `${window.REACT_APP_API_GATEWAY_URL}/cms/api/Asset/DownloadFileByFileName?fileName=email-attachments%2f<file-name-here>&getByFileNameOnly=false`,
  downloadMyDocument: `${window.REACT_APP_API_GATEWAY_URL}/personal/api/Asset/download-mydocument?documentId=`,
  saveLogWhenUserLoginErrors: `${window.REACT_APP_API_GATEWAY_URL}/personal/api/personal-data/Producer/SaveLogWhenUserLoginErrors`,
};
